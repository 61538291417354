export default {
  error_occurred: 'Omlouváme se, došlo k chybě. Zkuste to prosím znovu za chvíli.',
  new_content_available: 'Proběhla nová aktualizace stránky, kliknutím na „Obnovit“ stránku znovu načtěte.',
  refresh_button_title: 'Obnovit',
  force_reload_message: 'Omlouváme se, došlo k chybě. Náš tým už na tom pracuje. Zkuste prosím web za chvíli znovu načíst.',
  sign_up: 'Vytvořit účet',
  service: 'Servis',
  employee: 'Zaměstnanec',
  select_employee: 'Vyberte osobu',
  employees: 'Zaměstnanci',
  services: 'Služby',
  duration: 'Doba trvání',
  from: 'Z',
  select_date: 'Vyberte datum',
  no_account_question: 'Ještě nemáte účet?',
  name: 'Jméno',
  surname: 'Příjmení',
  name_and_surname: 'Celé jméno',
  phone: 'Telefon',
  password: 'Heslo',
  field_is_required: 'Toto pole je povinné.',
  value_is_too_long: 'Hodnota je příliš dlouhá.',
  value_is_too_short: 'Hodnota je příliš krátká.',
  signup_was_successful: 'Registrace proběhla úspěšně. Přihlášení do panelu.',
  signup_has_failed: 'Registrace se nezdařila. Zkuste to prosím znovu za chvíli.',
  email_is_taken: 'Účet s danou e-mailovou adresou již existuje. Pokud jste zapomněli heslo, použijte možnost připomenutí.',
  email_or_password_incorrect: 'E-mailová adresa nebo heslo jsou nesprávné. Pokud jste zapomněli heslo, použijte možnost připomenutí.',
  account_not_found: 'Účet nenalezen.',
  page_not_found: 'Jejda! Stránka, kterou jste hledali, nebyla nalezena :(',
  go_back_to_main_page: 'Vraťte se na domovskou stránku a zkuste to znovu.',
  bookings: 'Rezervace',
  account: 'Můj účet',
  date: 'Datum',
  cancel: 'Zrušení',
  date_from: 'Datum od',
  date_to: 'Datum do',
  nothing_found_here: 'Nic nebylo nalezeno.',
  old_password: 'Staré heslo',
  remove: 'Vymazat',
  phone_is_invalid: 'Telefonní číslo je nesprávné.',
  book: 'Rezervovat',
  sign_up_2: 'Vytvořit účet',
  log_in: 'Přihlásit se',
  your_email_address: 'Vaše emailová adresa',
  select: 'Vybrat',
  filters: 'Filtry',
  expand: 'Rozšířit',
  collapse: 'Kolaps',
  bookings_drawer_title: 'Výběrem filtrů zobrazíte pouze rezervace, které hledáte.',
  clear_filters: 'Resetovat filtry',
  show_more: 'Zobrazit více',
  dont_you_remember_password: 'Zapomněli jste heslo?',
  password_information: 'Heslo by mělo obsahovat alespoň 8 znaků, obsahovat velké písmeno, číslici a speciální znak.',
  forgotten_password_button: 'Pošlete odkaz pro změnu hesla',
  do_you_already_have_an_account: 'Máte již účet?',
  reset_password_title: 'Nastavte nové heslo',
  reset_password_subtitle: 'Níže zadejte své nové heslo.',
  reset_password_button: 'Uložte nové heslo',
  profile: 'Profil',
  logout: 'Odhlásit se',
  cancel_booking: 'Zrušte svou rezervaci',
  status_approved: 'Schválený',
  status_payment: 'Čekání na platbu',
  status_done: 'Dokončeno',
  status_cancelled: 'Zrušeno',
  cancel_booking_dialog_title: 'Chcete zrušit svou rezervaci?',
  cancel_booking_dialog_information: 'Tuto akci nelze vrátit zpět!',
  cancel_booking_dialog_button: 'Ano, zrušit moji rezervaci',
  my_profile: 'Můj profil',
  my_data: 'Moje detaily',
  upload_new_photo: 'Přidat novou fotku',
  birthday: 'Narozeniny',
  save_changes: 'Uložit změny',
  phone_number: 'Telefonní číslo',
  directional: 'Kód oblasti',
  save_new_password: 'Uložte nové heslo',
  new_password: 'Nové heslo',
  password_change: 'Změnit heslo',
  view: 'Pohled',
  services_2: 'Služby',
  accept_terms_and_conditions_1: 'Kliknutím na možnost níže souhlasím s',
  accept_terms_and_conditions_2: 'Pravidla a podmínky',
  accept_terms_and_conditions_3: 'a potvrzuji, že jsem četl',
  accept_terms_and_conditions_4: 'Prohlášení o ochraně soukromí.',
  to: 'Na',
  pln: 'PLN @{value}',
  usd: '$@{value}',
  eur: 'EUR @{value}',
  gbp: '£@{value}',
  jpy: 'JPY @{value}',
  aud: 'AUD @{value}',
  cad: 'CAD @{value}',
  chf: 'CHF @{value}',
  sek: 'SEK @{value}',
  nzd: 'NZD @{value}',
  brl: 'BRL @{value}',
  bgn: 'BGN @{value}',
  czk: 'CZK @{value}',
  dkk: 'DKK @{value}',
  hkd: 'HKD @{value}',
  huf: 'HUF @{value}',
  ils: 'ILS @{value}',
  myr: 'MYR @{value}',
  mxn: 'MXN @{value}',
  twd: 'TWD @{value}',
  nok: 'NOK @{value}',
  php: 'PHP @{value}',
  ron: 'RON @{value}',
  rub: 'RUB @{value}',
  sgd: 'SGD @{value}',
  thb: 'THB @{value}',
  uah: '@{value} UAH',
  inr: '@{value} INR',
  clp: '@{value} CLP',
  login: 'Přihlásit se',
  email_is_incorrect: 'E-mailová adresa je nesprávná.',
  logged_out: 'Byli jste odhlášeni',
  booking_dialog_date_is_already_taken: 'Vybraný čas není k dispozici. Nevadilo by vám vybrat jiné místo?',
  data_search: 'Vyhledávání dat. Prosím, čekejte...',
  no_employees: 'Žádní zaměstnanci',
  no_services: 'Žádné služby',
  no_categories: 'Žádné kategorie',
  no_bookings: 'Rezervace nenalezeny.',
  no_subscriptions: 'Nebyly nalezeny žádné odběry.',
  female: 'ŽEnský',
  male: 'Mužský',
  other: 'Jiný',
  no_numeric_in_password: 'V hesle není žádný číselný znak.',
  no_lowercase_in_password: 'V hesle není žádné malé písmeno.',
  no_uppercase_in_password: 'V hesle není žádné velké písmeno.',
  no_special_character: 'Žádná zvláštní postava.',
  min_n_characters: 'Minimální počet znaků je @{min}.',
  max_n_characters: 'Maximální počet znaků je @{max}.',
  email: 'E-mailem',
  reset_password_fail_text: 'Heslo nebylo změněno. Odkaz pravděpodobně vypršel.',
  reset_password_success_title: 'Vaše heslo bylo změněno.',
  reset_password_success_text: 'Můžete se přihlásit pomocí svého nového hesla.',
  account_activation: 'Aktivace účtu',
  account_activation_success: 'Účet byl aktivován.',
  account_activation_fail: 'Při aktivaci vašeho účtu došlo k problému. Zdá se, že účet je již aktivní nebo odkaz již není platný.',
  account_activation_processing: 'Aktivace vašeho účtu. Prosím, čekejte...',
  account_activation_resend_title: 'Účet nebyl aktivován',
  account_activation_resend_text: 'Aktivujte svůj účet kliknutím na aktivační odkaz v e-mailu, který jsme vám zaslali.',
  account_activation_resend_button: 'Poslat znovu',
  confirm_send_activate_link_dialog_subtitle: 'Na výše uvedenou e-mailovou adresu jsme zaslali e-mail s aktivačním odkazem.',
  file_is_too_large: 'Vybraný soubor je příliš velký.',
  appointment_title: 'Vyberte osobu, datum a čas rezervace',
  others: 'Jiný',
  countryLabel: 'Země',
  requiredMessages: 'Je vyžadováno telefonní číslo',
  date_of_visit: 'Datum',
  hour_of_visit: 'Čas',
  thank_you_for_booking: 'Vaše rezervace',
  no_free_hours: 'Ve vybraný den nejsou k dispozici žádné volné časové úseky.',
  any_person: 'Náhodný výběr',
  password_changed: 'Vaše heslo bylo změněno',
  incorrect_old_password: 'Staré heslo je nesprávné',
  error404: 'Chyba 404',
  minutes: 'min',
  hours: 'h',
  days: 'd',
  ok: 'OK',
  web_page_temporary_disabled: 'Promiňte! Tato stránka byla dočasně deaktivována.',
  profile_update_success: 'Vaše údaje byly uloženy.',
  form_is_invalid: 'Formulář obsahuje chyby. Zkontrolujte prosím zvýrazněná pole a zkuste to znovu.',
  search: 'Vyhledávání',
  pay_online_button_title: 'Platit online',
  account_not_activated_info: 'E-mailová adresa nebyla potvrzena. Potvrďte svou e-mailovou adresu kliknutím na odkaz v odeslané zprávě.',
  paid: 'Zaplaceno',
  payments: 'Platby',
  payment_service: 'Servis',
  payment_date: 'Datum',
  payment_price: 'Cena',
  payment_success_title: 'Platba proběhla úspěšně, děkujeme!',
  payment_fail_title: 'Platba se nezdařila, zkuste to prosím znovu.',
  pay_again: 'Zaplať znovu',
  newsletter_subscribe_success: 'Děkuji! Vaše e-mailová adresa byla potvrzena.',
  newsletter_subscribe_fail: 'Při aktivaci vaší e-mailové adresy došlo k problému. Zdá se, že e-mailová adresa je již aktivní nebo odkaz již není platný.',
  newsletter_send_again: 'Poslat znovu',
  newsletter_send_again_success: 'Aktivační odkaz byl znovu odeslán.',
  send_again_fail: 'Při generování nového aktivačního odkazu došlo k problému, možná je vaše e-mailová adresa již aktivní nebo jste právě vygenerovali odkaz.',
  newsletter_email_is_used: 'E-mailová adresa je již na seznamu, pokud adresa nebyla potvrzena, můžeme znovu poslat aktivační odkaz.',
  newsletter_unsubscribe_success: 'Děkuji! Vaše e-mailová adresa byla odstraněna.',
  newsletter_unsubscribe_fail: 'Při mazání e-mailové adresy došlo k problému. Zdá se, že e-mailová adresa již byla smazána nebo vypršela platnost odkazu.',
  booking_payments: 'Rezervace',
  simple_store_product_transactions: 'Produkty',
  payment_type_select_box: 'Vyberte typ platby',
  payment_description: 'Popis',
  add_to_cart: 'Přidat do košíku',
  remove_from_cart: 'Odebrat z košíku',
  save: 'Uložit',
  bookings_cart_summary: 'Shrnutí rezervace',
  today: 'Dnes',
  fill_booking_details_title: 'Zadejte podrobnosti',
  create_an_account_question: 'Vytvořte si účet a zašlete heslo na e-mailovou adresu',
  value_is_incorrect: 'Tato hodnota není platná.',
  first_available_date: 'Dostupné časové úseky zapnuty',
  service_location_1: 'Adresa',
  service_location_2: 'Telefon',
  service_location_3: 'Google Meet',
  service_location_4: 'Jiný',
  service_location_5: 'Zoom',
  service_location_6: 'Microsoft Teams',
  service_location_7: 'Skype',
  service_location_8: 'WhatsApp',
  service_location: 'Umístění',
  booking_not_found: 'Rezervace nenalezena.',
  booking_canceled: 'Rezervace byla zrušena.',
  booking_canceled_fail: 'Tuto rezervaci nelze zrušit.',
  day: 'Den',
  week: 'Týden',
  month: 'Měsíc',
  year: 'Rok',
  booking_plural_1: 'Rezervace',
  booking_plural_2: 'Rezervace',
  booking_plural_3: 'Rezervace',
  booking_plural_4: 'Rezervace',
  booking_plural_5: 'Rezervace',
  day_plural_1: 'Den',
  day_plural_2: 'Dny',
  day_plural_3: 'Dny',
  day_plural_4: 'Dny',
  day_plural_5: 'Dny',
  week_plural_1: 'Týden',
  week_plural_2: 'Týdny',
  week_plural_3: 'Týdny',
  week_plural_4: 'Týdny',
  week_plural_5: 'Týdny',
  month_plural_1: 'Měsíc',
  month_plural_2: 'Měsíce',
  month_plural_3: 'Měsíce',
  month_plural_4: 'Měsíce',
  month_plural_5: 'Měsíce',
  year_plural_1: 'Rok',
  year_plural_2: 'Let',
  year_plural_3: 'Let',
  year_plural_4: 'Let',
  year_plural_5: 'Let',
  tax: 'DIČ',
  tax_prefix: 'Předpona',
  get_company_data: 'Vyhledávání',
  get_company_data_failed: 'Data se nepodařilo stáhnout.',
  company_name: 'Jméno společnosti',
  street_and_number: 'Ulice a číslo',
  postcode: 'Poštovní směrovací číslo',
  city: 'Město',
  country: 'Země',
  subscribe: 'Předplatit',
  recurrent_fee_info: 'Účtováno každý',
  one_time_fee_info: 'Jednorázový poplatek.',
  new_account_title: 'Vytvořit nový účet',
  payment_data_title: 'Platební údaje',
  billing_data_checkbox_question: 'Fakturační údaje',
  confirmation_modal_header: 'Akce vyžaduje potvrzení',
  confirmation_modal_warning: 'Jste si jistý, že chcete pokračovat?',
  no_way_to_undo_action: 'Tuto akci nelze vrátit zpět!',
  delete_payment_method: 'Vyjměte kartu',
  add_payment_method: 'Přidat kartu',
  fill_card_details: 'Vyplňte platební údaje.',
  subscriptions: 'Předplatné',
  subscription: 'Předplatné',
  invoice_number: 'Faktura',
  number: 'Číslo',
  status: 'Postavení',
  details: 'Podrobnosti',
  created_at: 'Vytvořeno',
  subscription_status_initiated: 'Nezaplaceno',
  subscription_status_active: 'Aktivní',
  subscription_status_inactive: 'Neaktivní',
  subscription_status_canceled: 'Zrušeno',
  ends_at: 'Končí v',
  ends_at_default: 'Platí do konce zúčtovacího období',
  select_subscription_to_pay: 'Vyberte předplatné, které chcete zaplatit.',
  pay_with_subscription: 'Plaťte předplatným',
  bookings_paid_by_subscription_success_message: 'Zaplaceno',
  bookings_not_paid_by_subscription_error_message: 'Rezervaci nebylo možné zaplatit vybraným předplatným.',
  stripe: 'Stripe',
  provider_tag: 'Metoda',
  card: 'Kartu',
  cash: 'Hotovost',
  transfer: 'Převod',
  promo_code_label: 'Slevový kód',
  promo_code_invalid_message: 'Poskytnutý slevový kód je neplatný.',
  payment_method_setup_error: 'Vyskytl se problém s vaší kartou. Vaše banka odmítla autorizovat kartu pro pozdější transakce. Kontaktujte prosím svou banku.',
  active_subscription_missing: 'Rezervace této služby vyžaduje aktivní předplatné. Bohužel nemáte žádné předplatné, které byste mohli použít.',
  area_code: 'Kód oblasti',
  usage: 'Používání',
  create_account_in_stripe_failed: 'Platební operátor odmítl vaše předplatné. Zkontrolujte prosím správnost formuláře (daňové identifikační číslo, telefonní číslo, e-mailová adresa).',
  additional_auth_required: 'Vaše banka vyžaduje další autorizaci',
  general_payment_error: 'Vyskytl se problém s vaší platební metodou. Možná vaše karta nepodporuje předplatné. Kontaktujte svou banku nebo zvolte jiný způsob platby.',
  subscription_set_successfully: 'Předplatné je nastaveno správně.',
  booking_created: 'Nová rezervace',
  booking_updated: 'Změna rezervace',
  booking_deleted: 'Smazání rezervace',
  booking_reminder: 'Připomenutí rezervace',
  email_notifications: 'Upozornění e-mailem',
  sms_notifications: 'SMS notifikace',
  save_user_preferences: 'Uložit předvolby',
  free_spots: 'Zbývají místa',
  no: 'Ne',
  yes: 'Ano',
  p24_inactive: 'Platebního operátora Przelewy24 nelze použít. Kontaktujte prosím svého platebního operátora.',
  stripe_customer_invalid: 'Neplatné ID zákazníka Stripe. Způsob platby nelze připojit k zákazníkovi.',
  stripe_resource_invalid: 'Vybraný produkt není dostupný. Ověřte si prosím dostupnost produktu u platebního operátora.',
  account_blocked_message: 'Váš účet je neaktivní. Kontaktujte prosím správce.',
  time_zone: 'Časové pásmo',
  no_spots_left: 'Dosáhli jste maximálního počtu dostupných míst.',
  employee_auth_required: 'Přihlaste se prosím ke svému účtu nebo použijte jinou e-mailovou adresu. Zaměstnanecké účty vyžadují přihlášení.',
  subpage_auth_required: 'Pro zobrazení karty se prosím přihlaste ke svému účtu.',
  gross: 'Hrubá částka.',
  subscription_login_info: 'Máte již účet? Pro vyplnění údajů se prosím přihlaste níže.',
  change_date_time: 'Přeplánovat',
  promo_code_checkbox: 'Mám slevový kód',
  booking_promo_code_label: 'Zadejte slevový kód',
  select_hour: 'Vyberte čas',
  id: 'Id',
  booking_rescheduled: 'Vaše rezervace byla změněna.',
  booking_rescheduled_fail: 'Tuto rezervaci nelze změnit.',
  max_user_bookings_error: 'Překročili jste maximální počet rezervací. Vyberte si prosím jinou službu nebo nás kontaktujte.',
  in_total: 'Celkem',
  company: 'Společnost',
  individual: 'Soukromá osoba',
  bulk_payment: 'Hromadná platba',
  make_bulk_payment: 'Hromadná platba',
  simple_store_product_confirmation_title: 'Vyplňte nákupní formulář',
  select_service_type: 'Vyberte možnost služby',
  add_to_calendar_button_label: 'Přidat do kalendáře',
  login_to_use_service_with_subscription: 'Přihlaste se prosím ke svému účtu a rezervujte si schůzku pro tuto službu.',
  booking_dialog_date_is_already_taken_multiple_bookings: 'Vybraný termín není dostupný. Vyberte prosím jiný volný termín. V případě rezervace více termínů se ujistěte, že se termíny vzájemně nevylučují.',
  select_location_calendar_warning: 'Vyberte místo pro načtení dostupných časů.',
  selectable_location_online_title: 'Online',
  change: 'Změnit',
  show_less_hours_button: 'Ukázat méně hodin',
  show_more_hours_button: 'Ukázat více hodin',
  add_another_button_title: 'Přidat další',
  close: 'Zavřít',
  selected_time_slots: 'Vybrané časové intervaly',
  select_location: 'Vyberte umístění',
  captcha_invalid_error_notification: 'Ověření captcha selhalo. Zkuste stránku znovu načíst, pokud problém přetrvává.',
  verify_code_incorrect_error: 'Zadaný kód je nesprávný.',
  verify_code_too_many_requests: 'Momentálně nemůžeme vygenerovat nový kód. Zkuste to později znovu nebo použijte kód, který jsme již zaslali.',
  send_again_button_title: 'Znovu odeslat',
  verify_title: 'Ověřit',
  verify_code_title: 'Zadejte ověřovací kód',
  verify_code_description: 'Poslali jsme vám 6místný ověřovací kód na váš e-mail. Zadejte jej prosím níže:',
  verification_invalid_error_notification: 'Neplatné ověření. Zkuste to znovu později.'
}
